var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" SIA ")])],1),_c('b-card-title',{staticClass:"mb-1 text-center"},[_vm._v(" Verifikasi Akun ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Lakukan verifikasi data dasar untuk mendapatkan akses login "),_c('p',{staticClass:"mt-2"},[_c('strong',[_vm._v(" Langkah "+_vm._s(_vm.steps)+" dari 2")])])]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[(_vm.steps === 1)?_c('div',[_c('b-form-group',{attrs:{"label":"NIA (Nomor Induk Advokat)","label-for":"nia"}},[_c('validation-provider',{attrs:{"name":"nia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nia","state":errors.length > 0 ? false : null,"name":"register-nia","placeholder":"Nomor NIA"},model:{value:(_vm.nia),callback:function ($$v) {_vm.nia=$$v},expression:"nia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3650479640)})],1),_c('b-form-group',{attrs:{"label":"Nomor KTP","label-for":"ktp"}},[_c('validation-provider',{attrs:{"name":"ktp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ktp","state":errors.length > 0 ? false : null,"name":"register-ktp","placeholder":"Nomor KTP"},model:{value:(_vm.ktp),callback:function ($$v) {_vm.ktp=$$v},expression:"ktp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3893724056)})],1),_c('b-form-group',{attrs:{"label":"Tanggal Lahir","label-for":"tanggal-lahir"}},[_c('validation-provider',{attrs:{"name":"tanggal-lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"tanggal-lahir","state":errors.length > 0 ? false : null,"name":"register-tanggal-lahir","placeholder":"Tanggal Lahir"},model:{value:(_vm.tanggalLahir),callback:function ($$v) {_vm.tanggalLahir=$$v},expression:"tanggalLahir"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4165793894)})],1),_c('b-form-group',[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.site_key},on:{"verify":_vm.verifyMethod,"expired":_vm.expiredMethod}})],1),_c('b-button',{attrs:{"variant":"primary","block":"","disabled":_vm.disableBtnLanjut},on:{"click":_vm.verifikasi}},[(_vm.disableBtnLanjut)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Lanjut ")],1)],1):_vm._e()])],1),_c('validation-observer',{ref:"registerDaftar"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[(_vm.steps === 2)?_c('div',[_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null,"name":"register-username","placeholder":"Masukkan pilihan username anda"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2434791997)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"Email yang aktif"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1757253081)})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,668306460)})],1),_c('b-form-group',{attrs:{"label":"Konfirmasi Password","label-for":"konformasi-password"}},[_c('validation-provider',{attrs:{"name":"KonfirmasiPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"konfirmasi-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"Masukkan kembali password anda"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1478791703)})],1),_c('b-form-group',[_c('vue-recaptcha',{ref:"recaptchaDaftar",attrs:{"sitekey":_vm.site_key},on:{"verify":_vm.verifyMethod,"expired":_vm.expiredMethod}})],1),_c('b-button',{attrs:{"variant":"primary","block":"","disabled":_vm.disableBtnDaftar},on:{"click":_vm.daftar}},[(_vm.disableBtnDaftar)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Daftar ")],1)],1):_vm._e()])],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v("Sign in instead")])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }