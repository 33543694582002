<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            SIA
          </h2>
        </b-link>

        <b-card-title class="mb-1 text-center">
          Verifikasi Akun
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Lakukan verifikasi data dasar untuk mendapatkan akses login
          <p class="mt-2">
            <strong> Langkah {{ steps }} dari 2</strong>
          </p>
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <div v-if="steps === 1">
              <!-- username -->
              <b-form-group label="NIA (Nomor Induk Advokat)" label-for="nia">
                <validation-provider
                  #default="{ errors }"
                  name="nia"
                  rules="required"
                >
                  <b-form-input
                    id="nia"
                    v-model="nia"
                    :state="errors.length > 0 ? false : null"
                    name="register-nia"
                    placeholder="Nomor NIA"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Nomor KTP" label-for="ktp">
                <validation-provider
                  #default="{ errors }"
                  name="ktp"
                  rules="required"
                >
                  <b-form-input
                    id="ktp"
                    v-model="ktp"
                    :state="errors.length > 0 ? false : null"
                    name="register-ktp"
                    placeholder="Nomor KTP"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label="Tanggal Lahir" label-for="tanggal-lahir">
                <validation-provider
                  #default="{ errors }"
                  name="tanggal-lahir"
                  rules="required"
                >
                  <flat-pickr
                    id="tanggal-lahir"
                    v-model="tanggalLahir"
                    :state="errors.length > 0 ? false : null"
                    name="register-tanggal-lahir"
                    placeholder="Tanggal Lahir"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="site_key"
                  @verify="verifyMethod"
                  @expired="expiredMethod"
                />
              </b-form-group>

              <!-- verifikasi button -->
              <b-button
                variant="primary"
                block
                :disabled="disableBtnLanjut"
                @click="verifikasi"
              >
                <b-spinner v-if="disableBtnLanjut" small />
                Lanjut
              </b-button>
            </div>
          </b-form>
        </validation-observer>

        <validation-observer ref="registerDaftar">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <div v-if="steps === 2">
              <!-- username -->
              <b-form-group label="Username" label-for="username">
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="register-username"
                    placeholder="Masukkan pilihan username anda"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="regEmail"
                    :state="errors.length > 0 ? false : null"
                    name="register-email"
                    placeholder="Email yang aktif"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label="Password" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Konfirmasi Password"
                label-for="konformasi-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="KonfirmasiPassword"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="konfirmasi-password"
                      v-model="confirmPassword"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="Masukkan kembali password anda"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <vue-recaptcha
                  ref="recaptchaDaftar"
                  :sitekey="site_key"
                  @verify="verifyMethod"
                  @expired="expiredMethod"
                />
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit button -->
              <b-button
                variant="primary"
                block
                :disabled="disableBtnDaftar"
                @click="daftar"
              >
                <b-spinner v-if="disableBtnDaftar" small />
                Daftar
              </b-button>
            </div>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{ name: 'login' }">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  // BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { api, setToken } from '@/hap-sia/setup'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    // BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BSpinner,
    VueRecaptcha,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      nia: null,
      ktp: null,
      tanggalLahir: null,
      regEmail: '',
      username: '',
      password: '',
      confirmPassword: null,
      status: '',

      // validation rules
      required,
      email,

      steps: 1,
      disableBtnLanjut: false,
      disableBtnDaftar: false,
      recaptcha: '',
      site_key: process.env.VUE_APP_SIA_RECAPTCHA_SITEKEY,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    daftar() {
      this.$refs.registerDaftar.validate().then(async success => {
        if (success) {
          if (this.recaptcha !== '') {
            if (this.password !== this.confirmPassword) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Konfirmasi password tidak sesuai',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
              this.disableBtnDaftar = false
              return
            }
            this.disableBtnDaftar = true
            try {
              this.$refs.recaptchaDaftar.reset()
              const result = await api().post('akun/verifikasi-biodata', {
                nia: this.nia,
                ktp: this.ktp,
                tanggal_lahir: this.tanggalLahir,
                username: this.username,
                email: this.regEmail,
                password: this.password,
                recaptcha_token: this.recaptcha,
              })
              if (!result.data.id) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Gagal',
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }
              // lanjut login
              // this.login()
              this.disableBtnDaftar = false
              this.$toast(
                {
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${this.userFullName || this.username}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `You have successfully register in as ${this.username}. Now you can login!`,
                  },
                },
                {
                  timeout: 10000,
                },
              )
              this.$router.replace({ name: 'login' })
            } catch (error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
              this.disableBtnDaftar = false
            }
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Berhasil Verifikasi',
            //     icon: 'EditIcon',
            //     variant: 'success',
            //   },
            // })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Recaptcha Failed',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
    verifikasi() {
      // if (this.recaptcha !== '') {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          if (this.recaptcha !== '') {
            this.disableBtnLanjut = true
            try {
              this.$refs.recaptcha.reset()
              const result = await api().post('akun/verifikasi-biodata', {
                nia: this.nia,
                ktp: this.ktp,
                tanggal_lahir: this.tanggalLahir,
                recaptcha_token: this.recaptcha,
              })
              if (result.data) {
                this.recaptcha = ''
                this.steps = 2
              }
            } catch (error) {
              let errorText
              if (error.response) {
                errorText = error.response.data.message
              } else {
                errorText = error.message
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorText,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
              this.disableBtnLanjut = false
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Recaptcha Failed',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
    async login() {
      let userData
      try {
        const result = await api().post('akun/login', {
          username: this.username,
          password: this.password,
          recaptcha_token: this.recaptcha,
        })
        userData = result.data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Informasi',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${error.response.data.message}`,
          },
        })
      }
      setToken(userData.accessToken)
      // setRefreshToken(userData.refreshToken)
      userData.fullName = userData.name
      userData.role = userData.default_role
      userData.ability.push({
        action: 'read',
        subject: 'Auth',
      })
      localStorage.setItem('userData', JSON.stringify(userData))

      this.$ability.update(userData.ability)

      this.$router.replace(userData.redirect_url).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Welcome ${userData.name || userData.username}`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `You have successfully logged in as ${userData.default_role}. Now you can start to explore!`,
          },
        })
      })
    },
    verifyMethod(response) {
      this.recaptcha = response
    },
    expiredMethod() {
      this.recaptcha = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
